
:root {
    --header-height: 7rem;
    --footer-height: 8rem;
}

.App{
    background-color: #FAF9F6;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}