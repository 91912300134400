.inputRadioGroup {
    display: flex;
}

.inputRadioGroup>* {
    margin-left: 0.2rem;
}

.inputRadioGroup>* {
    margin-left: 0.2rem;
}

.inputRadioGroup>*:first-child {
    margin-right: 0rem;
    margin-left: 0.2rem;
}

.inputRadioGroup>*:last-child {
    margin-right: 0.2rem;
    margin-left: 0rem;
}

.redStar {

    color: red;
    font-size: 1.5rem;
    padding-right: 0.2rem;
}

.checkInput {

    margin-left: 0.5rem;
}

.radioBoxInput {
    width: 1.25em;
    height: 1.25rem;
    transition: all 0.125s ease-in-out;
}

.radioBoxInput:hover {
    scale: 1.125;
}


.radioBoxInputPrimary { accent-color: var(--bs-primary); }

.radioBoxInputSecondary { accent-color: var(--bs-secondary); }

.radioBoxInputSuccess { accent-color: var(--bs-success); }

.radioBoxInputDanger { accent-color: var(--bs-danger); }

.radioBoxInputWarning { accent-color: var(--bs-warning); }

.radioBoxInputInfo { accent-color: var(--bs-info); }

.radioBoxInputLight { accent-color: var(--bs-light); }

.radioBoxInputDark { accent-color: var(--bs-dark); }

.radioBoxInputPrimary:hover { accent-color: rgba(var(--bs-primary-rgb), 0.5); }

.radioBoxInputSecondary:hover { accent-color: rgba(var(--bs-secondary-rgb), 0.5); }

.radioBoxInputSuccess:hover { accent-color: rgba(var(--bs-success-rgb), 0.1); }

.radioBoxInputDanger:hover { accent-color: rgba(var(--bs-danger-rgb)); }

.radioBoxInputWarning:hover { accent-color: rgba(var(--bs-warning-rgb)); }

.radioBoxInputInfo:hover { accent-color: rgba(var(--bs-info-rgb)); }

.radioBoxInputLight:hover { accent-color: rgba(var(--bs-light-rgb)); }

.radioBoxInputDark:hover { accent-color: rgba(var(--bs-dark-rgb)); }

.radioBoxLabel {
    font-size: 1.2em;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: fit-content;
    cursor: pointer;
}