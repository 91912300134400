.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.50);
}

.modal {
  position: fixed;
  top: 12vh;
  left: 5%;
  width: 90%;
  background-color: white;
  overflow-wrap: break-word;
  overflow: hidden;
  scroll-behavior: smooth;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  max-height: 80vh;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.87);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.87);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.87);
}

.header {

  display: flex;
  justify-content: space-between;
  background-color: rgb(237, 237, 245);
  color: rgb(61, 62, 62);
  padding: 0.5rem;
  height: 7vh;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(219, 210, 210, 0.75);
  -moz-box-shadow: 0px 1px 5px 0px rgba(219, 210, 210, 0.75);
  box-shadow: 0px 1px 5px 0px rgba(219, 210, 210, 0.75);
}

.modalTitle {

  font-size: 1.5rem;
  font-weight: 400;
}

.modalBody {

  padding: 0.5rem;
  min-height: 7rem;
  font-size: 1.2rem;
  overflow: auto;
  width: 100%;
}


.hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.footer {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }

  .modalSizeXS {

    width: 20%;
    left: 40%;
  }

  .modalSizeS {

    width: 30%;
    left: 35%;
  }

  .modalSizeM {

    width: 40%;
    left: 30%;
  }

  .modalSizeL {

    width: 55%;
    height: 80vh;
    left: 22.5%;
  }

  .modalSizeXL {

    width: 94%;
    height: 80vh;
    left: 3%;
  }

}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}