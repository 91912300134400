.page{
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    padding-bottom: 4vh;
    padding-top: 2vh;
}

.header {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 100%;
    background-color: #ffffff;
    height: var(--header-height);
    box-shadow: 0px 3px 15px 3px rgba(0,0,0,0.1);
}

.emptyDiv {

    grid-area: 1 / 3 / 2 / 4;
}

.headerTitleDiv {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerTitle {
    font-size: 2rem;
    cursor: pointer;
    transition: all 0.5s ease;
}

.headerTitle:hover {
    background-color: rgba(253, 126, 20, 0.1);
    border-radius: 20px;
    padding: 0.5rem;
}

.lolaPortal {
    color: var(--bs-orange);
}

.cityLogoDiv {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.cityLogo {
    cursor: pointer;
    height: calc(var(--header-height) - 1rem);
    transition: transform 500ms;
}

.cityLogo:hover {
    transform: scale(1.03);

}

.footerDiv{
    display: flex;
    height: var(--footer-height);
    background-color: #d0e5f8;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
}

.logo{
    width: 200px;
    height: 60px;
}

.name{
    font-size: 1.5rem;
}

@media only screen and (max-width: 576px) {
    
    .footerDiv {
        height: auto;
        flex-direction: column;
        row-gap: 0.5rem;
        justify-content: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    
    .headerTitle {
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
    }

    .cityLogoDiv {
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 0rem;
        grid-area: 1 / 1 / 2 / 2;
    }

    .header {
        height: 12rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .headerTitleDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: unset;
        transform: none;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        grid-area: 2 / 1 / 3 / 2;
    }

    .headerTitle {
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
    }
}