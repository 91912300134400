.homePage {
    padding-top: 7rem;
}

.actionBoxContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 2rem;
}

.actionBox {
    border-radius: 5px;
    color: rgb(67, 65, 65);
    width: 18rem;
    height: 12rem;

    transition: transform 250ms;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.actionBox:hover {

    transform: scale(1.02);
    cursor: pointer;
}

.actionBox:active {
    outline-color: rgb(130, 192, 230);
    outline-style: solid;
    outline-width: 5px;
}

.actionBoxIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem; 
    font-size: 3.5rem;

    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(238, 241, 245, 1) 51%, rgba(218, 221, 231, 1) 100%);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

.actionBoxTitle {
  
    font-size: 1.5rem;
    text-align: center;
}

.otpBody {
    padding: 1rem;
}

.otpForm {}

@media only screen and (max-width: 576px) {
    .actionBoxContainer {
        justify-content: center;
    }

    .actionBox {
        width: 95%;
    }
}