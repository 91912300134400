
.checkInputTakanon{
    font-size: 1.4rem;
}

.checkInputForm{
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    column-gap: 5px;
}

.errMsgTakanon {
    color: red;
    font-size: 1rem;
}

.cofirmationContentDiv {
    padding: 1rem;
}

.cofirmationContentName {
    font-size: 1.4rem;
    font-weight: 500;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    
    .divSmallHeaderImg{

        text-align: center;
    }

    .smallHeaderImg{
        width: 50%;
        
    }

    .smallHeaderTitle{

        font-size: 1.8rem;
        padding-right: 0;
    }
}